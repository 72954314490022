import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { kebabCase } from 'lodash'
import { Link, graphql } from 'gatsby'
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import CustomLink from '../components/CustomLink'
import Layout from '../components/Layout'
import CustomPageContext from '../Context/CustomPageContext'
import { CreationsRoll } from '../components/CreationsRoll'
import MetaTags from '../components/MetaTags'
import appConfig from '../config/config'

const useStyles = makeStyles((theme) => ({
  section: {},
  topContainer: {
    padding: '1rem 0.5rem',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tagContainer: {
    padding: '1rem 0rem',
  },
  bottomActionContainer: {
    width: '100%',
    padding: '1rem 0',
    margin: '1rem 0rem',
    display: 'flex',
    justifyContent: 'center',
  },
  browseAllTagsLabel: {
    background: theme.palette.primary.main,
    transform: 'skew(-15deg)',
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    padding: '0.2rem 1rem',
    width: 'fit-content',
    boxShadow: '5px 5px 0px 1px grey',

    '&:active': {
      transform: 'translateY(10%) skew(-15deg)',
      boxShadow: '5px 5px 0px 0px grey',
    },
  },
}))

const TagRoute = ({ location, data, pageContext }) => {
  const initUiTheme =
    typeof window !== 'undefined' ? localStorage.getItem('uiTheme') : null
  const [uiTheme, setUiTheme] = useState(
    initUiTheme || appConfig.defaultInitTheme
  )
  const classes = useStyles()

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // type: prefersDarkMode ? 'dark' : 'light',
          type: uiTheme,
          primary: { main: '#212121' },
          secondary: { main: '#DD2C00' },
        },
      }),
    [uiTheme]
  )

  const { tag } = pageContext
  const { edges: posts } = data.allMarkdownRemark
  const { title } = data.site.siteMetadata
  const { totalCount } = data.allMarkdownRemark

  useEffect(() => {
    // Sets the theme config to localstore
    if (uiTheme) {
      localStorage.setItem('uiTheme', uiTheme)
    }
  }, [uiTheme])

  return (
    <CustomPageContext.Provider
      value={{
        uiTheme,
        setUiTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout location={location}>
          <section className={classes.section}>
            <MetaTags pageTitle={tag} />
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.topContainer}
            >
              <Grid
                item
                xs={11}
                sm={11}
                md={9}
                lg={8}
                xl={7}
                xxl={7}
                style={{ width: '100%' }}
              >
                <div className={classes.titleContainer}>
                  <Typography variant="h4" style={{ paddingRight: '1rem' }}>
                    Tag:
                  </Typography>
                  <Badge badgeContent={totalCount} color="secondary">
                    <Chip
                      size="medium"
                      avatar={<LocalOfferIcon style={{ width: 15 }} />}
                      label={tag}
                    />
                  </Badge>
                </div>
              </Grid>
            </Grid>
            <Divider />
            <CreationsRoll data={data} />
            <Divider />
            <div className={classes.bottomActionContainer}>
              <CustomLink to="/tags/">
                <Typography variant="h5" className={classes.browseAllTagsLabel}>
                  Browse all tags
                </Typography>
              </CustomLink>
            </div>
          </section>
        </Layout>
      </ThemeProvider>
    </CustomPageContext.Provider>
  )
}

TagRoute.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    tag: PropTypes.string,
  }),
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "creations-post" }
          tags: { in: [$tag] }
          hidePost: { in: [false, null] }
        }
      }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredPost
            featuredImage {
              id
              childImageSharp {
                fluid(maxWidth: 500, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
              colors {
                ...GatsbyImageColors
              }
            }
          }
        }
      }
    }
  }
`
